import makeStyles from '@mui/styles/makeStyles';
import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import client from '../../../../../../utilities/requestClient';
import KidsEditorGridItem from '../../layout/KidsEditorGridItem.component';
import { greys, mainColors } from '../../../../../../styling/theme';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import Raptor2Loading from '../../../../../feedback/Raptor2Loading';
import SaveConfirmationDialogBox from './SaveConfirmationDialogBox.component';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { KidsData } from '../KidsEditor';
import { useGenerateNewKIDPdf } from '../../services/mutations';
import DownloadPdfForm from './DownloadPdfForm';
import { Document, Page } from 'react-pdf';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { TextField, InputAdornment } from '@mui/material';
import ErrorBoundaryMessage from '../../../../../ui/ErrorBoundaryMessage';

interface EditorPdfViewerProps {
  priipsKidsData: KidsData[];
  isSaveEnabled: boolean;
  getTemplateChangesMade: () => string[];
  getCanUserSave: () => string[];
  resetContentAfterSave: () => void;
  figuresWereUpdated: boolean;
}

export interface priipsKidsDataPdfVersion {
  format_options: string;
  manco_id: string;
  tag: string;
  content: string;
  version: string;
  edited_by_name: string;
  edit_timestamp: string;
  edited_by: string;
  fund_name: string;
  comment_id: string | null;
  field_id: string;
  published_by: string;
  kiid_id: string;
  kiid_index: number;
  share_class_name: string;
  share_class: string;
  fund_id_string: string;
  has_unresolved_comment: boolean;
  document_language: string;
  published_by_name: string;
  fund_id: string;
  is_published: boolean;
}

export function convertToPdfVersion(data: KidsData): priipsKidsDataPdfVersion {
  let templateContent = null;
  if (data.templateId && data.templateType) {
    templateContent = {
      id: data.templateId,
      type: data.templateType,
      text: data.content,
    };
    templateContent = '{' + JSON.stringify(templateContent) + '}';
  }

  return {
    format_options: data.formatOptions,
    manco_id: data.mancoId,
    tag: data.tag,
    content: templateContent ? templateContent : data.content,
    version: data.version,
    edited_by_name: data.editedByName,
    edit_timestamp: data.editTimestamp,
    edited_by: data.editedBy,
    fund_name: data.fundName,
    comment_id: data.commentId,
    field_id: data.fieldId,
    published_by: data.publishedBy,
    kiid_id: data.kiidId,
    kiid_index: data.kiidIndex,
    share_class_name: data.shareClassName,
    share_class: data.shareClass,
    fund_id_string: data.fundIdString,
    has_unresolved_comment: data.hasUnresolvedComment,
    document_language: data.documentLanguage,
    published_by_name: data.publishedByName,
    fund_id: data.fundId,
    is_published: data.isPublished,
  };
}

const useStyles = makeStyles(() => ({
  viewerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 14rem)',
    border: `1px solid ${greys.grey200}`,
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    margin: 0,
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Changed to space-between for left/right groups
    padding: '0.75rem 1rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${greys.grey100}`,
    minHeight: '48px',
  },

  toolbarGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },

  divider: {
    height: '24px',
    width: '1px',
    backgroundColor: greys.grey200,
    margin: '0 0.5rem',
  },

  zoomControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },

  zoomButton: {
    minWidth: '28px !important',
    width: '28px',
    height: '28px',
    padding: '4px !important',
    border: `1px solid ${greys.grey200} !important`,
    '&:hover': {
      backgroundColor: greys.grey500,
    },
    '&.Mui-disabled': {
      borderColor: `${greys.grey100} !important`,
    },
  },

  zoomInput: {
    width: '64px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
    },
    '& input': {
      padding: '4px',
      textAlign: 'center',
      fontSize: '0.875rem',
    },
  },

  toolbarButton: {
    fontSize: '0.9rem',
    height: '3rem',
    padding: '0.2rem 1rem',
    color: greys.grey800,
    backgroundColor: 'white',
    borderRadius: '0.2rem',
    borderColor: greys.grey200,
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: greys.grey500,
    },
  },

  primaryButton: {
    fontSize: '0.9rem',
    height: '3rem',
    padding: '0.2rem 1rem',
    color: 'white',
    backgroundColor: mainColors.mainBlue,
    borderRadius: '0.2rem',
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&.Mui-disabled': {
      backgroundColor: greys.grey200,
      color: greys.grey400,
    },
  },

  iframeContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    backgroundColor: greys.grey200,
    padding: '2rem',
    position: 'relative',
  },

  documentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: greys.grey200,
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: greys.grey200,
    },
  },

  pageContainer: {
    margin: '1rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    width: 'fit-content',
    '& canvas': {
      display: 'block',
      backgroundColor: 'white',
    },
  },

  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '1123px',
    minWidth: '794px',
    backgroundColor: greys.grey200,
    margin: '1rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '1123px',
    minWidth: '794px',
    backgroundColor: 'white',
    margin: '1rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
}));

const EditorPdfViewer = forwardRef<
  { refreshPdf: () => void },
  EditorPdfViewerProps
>(
  (
    {
      priipsKidsData,
      isSaveEnabled,
      getTemplateChangesMade,
      getCanUserSave,
      resetContentAfterSave,
      figuresWereUpdated,
    },
    ref,
  ) => {
    // const { iframeTitle } = useRaidrKids() # Not currently used - but maybe we'll add a title to the viewer again after more design planning
    const createNewKIDPdf = useGenerateNewKIDPdf();
    const [saveDialogIsShowing, setSaveDialogIsShowing] = useState(false);

    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const pdfContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollPosRef = useRef<number>(0);

    // Add error boundary state
    const [hasError, setHasError] = useState(false);

    // Update refresh logic with better error handling
    const refreshPdf = async () => {
      if (!priipsKidsData?.length) return;

      try {
        setHasError(false);
        const content = priipsKidsData.map(convertToPdfVersion);
        const formData = new FormData();
        const priipsKidsJson = JSON.stringify(content);
        const fundId = content[0].fund_id_string;
        const fundName = content[0].fund_name;
        const isin = content[0].share_class;
        const documentLanguage = content[0].document_language;

        formData.append('contents', priipsKidsJson);
        formData.append('fund_id', fundId);
        formData.append('fund_name', fundName);
        formData.append('isin', isin);
        formData.append('document_language', documentLanguage);
        formData.append(
          'params',
          JSON.stringify([
            'contents',
            'fund_id',
            'fund_name',
            'isin',
            'document_language',
          ]),
        );

        const response = await createNewKIDPdf.mutateAsync(formData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = window.URL.createObjectURL(blob);

        if (pdfUrl) URL.revokeObjectURL(pdfUrl);
        setPdfUrl(fileUrl);
      } catch (error) {
        console.error('Failed to refresh PDF:', error);
        setHasError(true);
      }
    };

    // Expose refreshPdf to parent
    useImperativeHandle(ref, () => ({
      refreshPdf,
    }));

    // Initial load
    useEffect(() => {
      refreshPdf();
    }, []);

    const onDocumentLoadSuccess = (pdf: any) => {
      setNumPages(pdf.numPages);
      // Once PDF is loaded, restore scroll position:
      if (pdfContainerRef.current) {
        pdfContainerRef.current.scrollTop = scrollPosRef.current;
      }
    };

    const requestClient = client();
    const classes = useStyles();

    const { showAsyncSnackbar } = useSnackbar();

    async function checkTemplateChanges() {
      const formData = new FormData();
      const fieldIds = getTemplateChangesMade();
      if (fieldIds.length) {
        const changes: any[] = [];
        fieldIds.forEach((id) => {
          const element = priipsKidsData.find((elem) => elem.fieldId === id);
          changes.push({
            template_id: element?.templateId,
            text: element?.content,
          });
        });
        formData.append('template_updates', JSON.stringify(changes));
      } else {
        return [];
      }

      let errorOccured = false;
      let affectedDocuments: string[] = [];
      await requestClient
        .post('get_documents_affected_by_template_changes', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .catch((error) => {
          alert('There was an error while retrieving affected documents.');
          errorOccured = true;
          throw error;
        })
        .then((response) => {
          affectedDocuments = response.data;
        });
      return affectedDocuments;
    }

    async function handleSaveChanges(
      applyToAllDocuments: boolean,
      applyToAllShareClasses?: boolean,
    ) {
      if (!priipsKidsData.length) {
        return;
      }
      const content: priipsKidsDataPdfVersion[] = [];
      priipsKidsData.forEach((element: any) => {
        content.push(convertToPdfVersion(element));
      });

      const formData = new FormData();
      formData.append('kiid_data', JSON.stringify(content));

      const fieldIds = getTemplateChangesMade();
      if (fieldIds.length && applyToAllDocuments) {
        const changes: any[] = [];
        fieldIds.forEach((id) => {
          const element = priipsKidsData.find((elem) => elem.fieldId === id);
          changes.push({
            template_id: element?.templateId,
            text: element?.content,
          });
        });
        formData.append('template_updates', JSON.stringify(changes));
      }

      let errorOccured = false;
      await showAsyncSnackbar({
        startMessage: 'Saving Data.',
        successMessage: 'Data Saved.',
        failureMessage: 'An Error occured while saving.',
        promiseToResolve: requestClient
          .post('kiid_generator_write_kiid_data', formData, {
            headers: { 'content-type': 'multipart/form-data' },
          })
          .catch((error) => {
            alert('Error while saving changes.');
            errorOccured = true;
            throw error;
          })
          .finally(() => {
            if (!errorOccured) resetContentAfterSave();
          }),
      });

      if (applyToAllShareClasses) {
        await handleUpdateFigures(applyToAllShareClasses);
      }
      refreshPdf();
    }

    async function handleUpdateFigures(applyToAllShareClasses: boolean) {
      const formData = new FormData();

      formData.append('fund_id', priipsKidsData[0].fundIdString);
      formData.append('fund_uuid', priipsKidsData[0].fundId);
      formData.append('excluded_share_class', priipsKidsData[0].shareClass);
      formData.append(
        'only_apply_to_sepcified_share_class',
        applyToAllShareClasses ? 'no' : 'yes',
      );

      let errorOccured = false;
      await showAsyncSnackbar({
        startMessage: 'Updating Figures.',
        successMessage: 'Figures Updated Successfully.',
        failureMessage: 'An Error occured while updating figures.',
        promiseToResolve: requestClient
          .post('kiid_generator_refresh_figures_data', formData, {
            headers: { 'content-type': 'multipart/form-data' },
          })
          .catch((error) => {
            alert('Error while updating figures.');
            errorOccured = true;
            throw error;
          })
          .finally(() => {
            if (!errorOccured) resetContentAfterSave();
          }),
      });
      refreshPdf();
    }

    // Add zoom state
    const [scale, setScale] = useState(1);
    const [customZoom, setCustomZoom] = useState('100');
    const MIN_SCALE = 0.25;
    const MAX_SCALE = 4.0;
    const SCALE_STEP = 0.1;

    const handleZoomIn = () => {
      const newScale = Math.min(scale + SCALE_STEP, MAX_SCALE);
      setScale(newScale);
      setCustomZoom(Math.round(newScale * 100).toString());
    };

    const handleZoomOut = () => {
      const newScale = Math.max(scale - SCALE_STEP, MIN_SCALE);
      setScale(newScale);
      setCustomZoom(Math.round(newScale * 100).toString());
    };

    const handleResetZoom = () => {
      setScale(1);
      setCustomZoom('100');
    };

    const handleCustomZoomChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value.replace(/[^\d]/g, '');
      setCustomZoom(value);
    };

    const handleCustomZoomBlur = () => {
      let numValue = parseInt(customZoom, 10);
      if (isNaN(numValue)) numValue = 100;

      numValue = Math.max(Math.min(numValue, MAX_SCALE * 100), MIN_SCALE * 100);
      const newScale = numValue / 100;

      setScale(newScale);
      setCustomZoom(numValue.toString());
    };

    const handleCustomZoomKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleCustomZoomBlur();
      }
    };

    // Update the loading UI
    const renderLoadingUI = (
      <div className={classes.loadingContainer}>
        <Raptor2Loading centerWrap messages={['Generating Document...']} />
      </div>
    );

    // Add near other event handlers
    const handleScroll = () => {
      if (pdfContainerRef.current) {
        scrollPosRef.current = pdfContainerRef.current.scrollTop;
      }
    };

    return (
      <KidsEditorGridItem xs={12}>
        <div className={classes.viewerContainer}>
          <div className={classes.toolbar}>
            {/* Left group - Zoom controls */}
            <div className={classes.toolbarGroup}>
              <div className={classes.zoomControls}>
                <Tooltip title="Zoom Out" arrow placement="top">
                  <span>
                    <Button
                      className={classes.zoomButton}
                      variant="outlined"
                      onClick={handleZoomOut}
                      disabled={scale <= MIN_SCALE}
                    >
                      <ZoomOutIcon fontSize="small" />
                    </Button>
                  </span>
                </Tooltip>

                <TextField
                  className={classes.zoomInput}
                  value={customZoom}
                  onChange={handleCustomZoomChange}
                  onBlur={handleCustomZoomBlur}
                  onKeyPress={handleCustomZoomKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  size="small"
                />

                <Tooltip title="Zoom In" arrow placement="top">
                  <span>
                    <Button
                      className={classes.zoomButton}
                      variant="outlined"
                      onClick={handleZoomIn}
                      disabled={scale >= MAX_SCALE}
                    >
                      <ZoomInIcon fontSize="small" />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="Reset Zoom" arrow placement="top">
                  <span>
                    <Button
                      className={classes.zoomButton}
                      variant="outlined"
                      onClick={handleResetZoom}
                      disabled={scale === 1}
                    >
                      <RestartAltIcon fontSize="small" />
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {/* Right group - Action buttons */}
            <div className={classes.toolbarGroup}>
              <Button
                className={classes.toolbarButton}
                variant="outlined"
                onClick={refreshPdf}
                startIcon={<RefreshIcon sx={{ fontSize: '1.5rem' }} />}
                disableElevation
              >
                Reload
              </Button>
              {saveDialogIsShowing && (
                <SaveConfirmationDialogBox
                  setDialogIsShowing={setSaveDialogIsShowing}
                  dialogIsShowing={saveDialogIsShowing}
                  getTemplateChangesMade={getTemplateChangesMade}
                  getCanUserSave={getCanUserSave}
                  handleSaveChanges={handleSaveChanges}
                  figuresWereUpdated={figuresWereUpdated}
                  handleUpdateFigures={handleUpdateFigures}
                  checkTemplateChanges={checkTemplateChanges}
                />
              )}
              <Button
                className={
                  isSaveEnabled ? classes.primaryButton : classes.toolbarButton
                }
                variant={isSaveEnabled ? 'contained' : 'outlined'}
                onClick={() => setSaveDialogIsShowing(true)}
                disabled={!isSaveEnabled}
                startIcon={<SaveIcon sx={{ fontSize: '1.5rem' }} />}
                disableElevation
              >
                Save
              </Button>

              <DownloadPdfForm priipsKidsData={priipsKidsData} />
            </div>
          </div>

          <div
            className={classes.iframeContainer}
            ref={pdfContainerRef}
            onScroll={handleScroll}
          >
            {hasError ? (
              <div className={classes.errorContainer}>
                <ErrorBoundaryMessage />
              </div>
            ) : (
              <>
                {(createNewKIDPdf.isPending || !pdfUrl) && renderLoadingUI}
                {pdfUrl && !hasError && (
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={() => setHasError(true)}
                    loading={renderLoadingUI}
                    className={classes.documentContainer}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className={classes.pageContainer}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={800 * scale}
                        renderMode="canvas"
                      />
                    ))}
                  </Document>
                )}
              </>
            )}
          </div>
        </div>
      </KidsEditorGridItem>
    );
  },
);

export default EditorPdfViewer;
