import React, { useEffect, useState } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import RaptorExportTiles from './RaptorExportTiles.component';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import { useDispatch } from 'react-redux';
import { getAllRelevantWeekdays } from '../../../../utilities/dateUtilities';
import { setWeekDayDate } from '../../markets/MarketsMain';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import useTitleBarDatePicker from '../../../../hooks/useTitleBarDatePicker';

const RaptorExportToday: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  const dispatch = useDispatch();
  const marketDate = useTitleBarDatePicker('reportDate');

  // Adepa require that the Saturday 31st of December 2022 be available in the date picker along with all week days
  // the solution is to geta  list of all weekedays and manually add 2022-12-31 to the list.
  // DC - 2023-05-04

  useEffect(() => {
    const weekdays = getAllRelevantWeekdays();
    weekdays.push('2022-12-31');
    const datePickersToAdd: DatePicker[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'reportDate',
        titleBarTitle: 'Report Date:',
        currentValue: setWeekDayDate(),
        availableDates: weekdays,
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        datePickersToAdd,
        APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('reportDate', REMOVE_DATE_PICKER_FROM_TITLEBAR),
      );
    };
  }, []);

  return <RaptorExportTiles positionDate={marketDate || setWeekDayDate()} />;
};

export default RaptorExportToday;
