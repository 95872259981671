import { CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { makeStyles } from '@mui/styles';
import hotToast, { Toaster as HotToaster } from 'react-hot-toast';
import { IconButton } from '@mui/material';
import { RaptorTheme } from '../../styling/theme';

export const Toaster = HotToaster;

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  title: string;
  message?: string;
  icon?: React.ReactNode;
  closeToast: () => void;
}

interface ToastOpts {
  title: string;
  message?: string;
  type?: 'success' | 'error' | 'warning' | 'default';
  duration?: number;
  size?: 'sm' | 'md' | 'lg';
}

export const scaleFontSize = (remValue: number): string =>
  `${remValue / 0.625}rem`;

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  toaster: {
    display: 'flex',
    width: 'fit-content', // w-8
    padding: '0.5rem', // 0.8rem (scaled up from 0.5rem)
    alignItems: 'center', // items-center
    justifyContent: 'center', // justify-center
    borderRadius: '0.5rem', // rounded-lg (scaled up from 0.5rem)
    background: 'white', // bg-white
    maxWidth: '50rem', // 80rem (scaled up from 50rem)
    gap: '0.5rem', // 0.8rem (scaled up from 0.5rem)
  },

  title: {
    fontSize: '2rem', // 3.2rem (scaled up from 2rem)
    fontWeight: 'bold', // font-bold
    marginLeft: '0.75rem', // ml-3 (scaled up from 0.75rem)
    margin: '0',
  },

  message: {
    textAlign: 'left',
    margin: '0',
    marginLeft: '0.75rem', // ml-3 (scaled up from 0.75rem)
    fontSize: '1.4rem', // 2.24rem (scaled up from 1.4rem)
    fontWeight: 'normal', // font-normal
  },

  srOnly: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    borderWidth: 0,
  },
}));

export function Toast({
  visible,
  icon,
  className,
  title,
  message,
  closeToast,
  ...props
}: ToastProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.toaster}
      style={{
        visibility: visible ? 'visible' : 'hidden',
      }}
      {...props}
    >
      {icon}
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.message}>{message}</p>
      </div>
      <IconButton onClick={closeToast} style={{ padding: 8 }}>
        <span className={classes.srOnly}>Close</span>
        <DisabledByDefaultIcon style={{ fontSize: 24, color: 'black' }} />
      </IconButton>
    </div>
  );
}

export function toast(opts: ToastOpts) {
  const { title, message, type = 'default', duration = 3000 } = opts;

  return hotToast.custom(
    (t) => (
      <Toast
        title={title}
        message={message}
        visible={t.visible}
        icon={
          type === 'success' ? (
            <CheckCircle />
          ) : type === 'error' ? (
            <ErrorIcon />
          ) : type === 'warning' ? (
            <WarningIcon />
          ) : null
        }
        style={{
          border:
            type === 'success'
              ? '2px solid #10B981'
              : type === 'error'
                ? '2px solid #EF4444'
                : type === 'warning'
                  ? '2px solid #F59E0B'
                  : '2px solid #9CA3AF',
          color:
            type === 'success'
              ? '#10B981'
              : type === 'error'
                ? '#EF4444'
                : type === 'warning'
                  ? '#F59E0B'
                  : '#9CA3AF',
        }}
        closeToast={() => {
          hotToast.remove(t.id);
        }}
      ></Toast>
    ),
    { duration },
  );
}
