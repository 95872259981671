import { useMutation } from '@tanstack/react-query';
import { updatePosition } from './api.routes';
import { queryClient } from '../../../../../App';

// FormData should be in this shape -> PositionUpdatePostData

export function useUpdatedPositionData() {
  return useMutation({
    mutationFn: (data: FormData) => updatePosition(data),
    // onMutate: () => {
    //   console.log('mutate');
    // },

    // onError: () => {
    //   console.log('error');
    // },

    onSuccess: (data, variables) => {
      const fundName = variables.get('fund_name');
      queryClient.invalidateQueries({
        queryKey: ['editable-positions-data', fundName],
      });
    },

    // onSettled: async (_, error, variables) => {
    //   console.log('settled');
    // },
  });
}
