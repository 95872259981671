import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Button, Grid } from '@mui/material';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import {
  buildPortalColumns,
  buildPortalData,
  portalUpdateColumns,
  PortalUpdatePosition,
  updateColumnTitles,
} from './utils/buildFns';
import UltraTable, { TableData } from '../../../tables/ultraTable/UltraTable';
import {
  ColumnData,
  ColumnFields,
} from '../../../tables/ultraTable/types/column.types';
import { toast } from '../../../toast/Toast';
import { UltraTablePreset } from '../../../tables/ultraTable/types/presets.types';
import { useEffect, useState } from 'react';
import useConfirmation from '../../../../hooks/useConfirmation';
import {
  AuthorisedFundConfigType,
  AuthorisedKey,
  DefaultColumn,
  PositionUpdatePostData,
} from './PositionsUpdatePortal.types';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import PortalWarning from './components/PortalWarning';
import { PortalFetchWrapper } from './PortalFetchWrapper';
import NavigationGuard from '../../../ui/NavigationGuard';
import { useEditablePositionsData } from './services/queries';
import { isEmptyObject } from '../../../../utilities/helpers/gen/objects';
import { useUpdatedPositionData } from './services/mutations';

const NESTED_KEY_REGEX = /---x3A\/8n2\/G3c\/w5L---/g;

function transformAuthorisedKeys(
  authorisedKeys: AuthorisedKey[],
): (Omit<AuthorisedKey, 'key_path_name'> & { key_name: string })[] {
  return authorisedKeys.map(({ key_path_name, ...rest }) => ({
    ...rest,
    key_name: key_path_name, // Rename key_path_name to key_name
  }));
}

interface ExtendedFundInfoComponentProps extends FundInfoComponentProps {
  authConfig?: AuthorisedFundConfigType; // This is optional as I dont know how to tell typescript that the data will be there as it is handled in the FetchPortalWrapper
}

const PositionUpdatePortalComponent: React.FC<
  ExtendedFundInfoComponentProps
> = (props) => {
  const { handleConfirmation } = useConfirmation();

  const [updateAttempted, setUpdateAttempted] = useState(false);
  const [isDataEqual, setIsDataEqual] = useState(true);

  const handleDataComparison = (isEqual: boolean) => {
    setIsDataEqual(isEqual);
  };

  const fundId = props?.fundId;

  const { data, isPending, error } = useEditablePositionsData(
    fundId,
    props.authConfig,
  );

  const updatePositionData = useUpdatedPositionData();

  const positionDate = data?.returned_position_date || '';

  // Handles the beforeunload event to prompt the user if they want to leave the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // We are not handling on updateAttempted as we cannot change the browser message and as such dont want to confuse the user
      if (!isDataEqual) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataEqual]);

  if (isPending) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Position Update Portal...',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (!data) {
    return <NoDataMessage message={'No Data Available'} />;
  }

  const { authorised_keys, default_columns } = props.authConfig!; // I know this will be seeded as errors are handled in the wrapper

  // This transforms the key_name_path to key_name as key_name is used everywhere in frontend
  const transformedAuthKeyNameArr = transformAuthorisedKeys(authorised_keys);

  // This can also be fetched from the auth_keys endpoint - this can be used if ! transformedAuthKeyNameArr. Auth keys will render all the auth keys as presets
  const editableFields = data.positions.reduce((max: any[], item: any) => {
    return item.editable_fields && item.editable_fields.length > max.length
      ? item.editable_fields
      : max;
  }, []);

  const hasFunds = isEmptyObject(transformedAuthKeyNameArr);

  const mutatedColumns = MutateColumnsToIncludeEditableFields(
    portalUpdateColumns,
    transformedAuthKeyNameArr,
  );

  const originalData = {
    data,
    columns: hasFunds ? mutatedColumns : portalUpdateColumns,
  };

  const mutatedData = buildPortalData(originalData.data);

  const handleUpdate = async (
    originalRow: any,
    columnId: any,
    valueToUpdate: any,
  ) => {
    if (!fundId) {
      toast({
        title: 'Error updating field',
        message: `No Fund Id or Position Date`,
        type: 'error',
      });
      return;
    }

    // Create the FormData object to send to the backend
    const formattedKey = columnId.replace(NESTED_KEY_REGEX, '.');
    // Data in this
    const data: PositionUpdatePostData = {
      fund_name: fundId, // Currently this is the fund_name - but should be the fund_id
      selected_position_date: positionDate,
      position_id: originalRow.position_id,
      targeted_position_key_path: formattedKey,
      targeted_position_value: valueToUpdate,
    };

    // We must post form data to the backend as it expects it in this form not JSON body
    const formData = new FormData();

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(
          key,
          data[key as keyof PositionUpdatePostData] as string | Blob,
        );
      }
    }

    // Create the request
    await updatePositionData.mutateAsync(formData);

    setUpdateAttempted(true);
  };

  const handleRecalculation = async () => {
    if (!fundId) {
      toast({
        title: 'Error finding Fund',
        message: `No Fund Id available`,
        type: 'error',
      });
      return;
    }

    try {
      await axios.get(`${BASE_URL}request_recalculation/${fundId}`, {
        withCredentials: true,
      });

      toast({
        title: 'Recalculation Requested',
        message: `Recalculation requested for ${fundId}, please be patient`,
        type: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error requesting recalculation',
        message: `Error requesting recalculation: ${error}`,
        type: 'error',
      });
    }

    setUpdateAttempted(false);
  };

  const columnTitlesInfo: DefaultColumn[] = default_columns.map((col) => {
    return {
      column_name: col.column_name,
      column_key: col.column_key,
    };
  });

  const authorisedKeysInfo: DefaultColumn[] = transformedAuthKeyNameArr.map(
    (key) => {
      return {
        column_name: key.column_name,
        column_key: key.key_name,
      };
    },
  );

  const combinedColumnTitlesInfo: DefaultColumn[] = [
    ...columnTitlesInfo,
    ...authorisedKeysInfo,
  ];

  // Remove duplicates based on column_key
  const uniqueColumnTitlesInfo: DefaultColumn[] = Array.from(
    new Map(
      combinedColumnTitlesInfo.map((item) => [item.column_key, item]),
    ).values(),
  );

  const columns = buildPortalColumns(
    updateColumnTitles(uniqueColumnTitlesInfo, originalData.columns),
    handleUpdate,
  );

  const cleanData = mutatedData.map(({ etf_classification, ...rest }) => rest);

  const tableData: TableData<PortalUpdatePosition> = {
    data: cleanData,
    columns,
  };

  const presets = createHedgeFundPresets(
    transformedAuthKeyNameArr,
    default_columns,
    // hedgeDerivativesColumns,
  ); // The auth keys are the presets in this instance

  const onConfirmation = () => {
    handleConfirmation(
      'Recalculation Request',
      <>
        <strong>
          Are you sure you want to alert Risksystem to recalculate the fund?
        </strong>
        <p>
          Please make sure you have submitted all changes before requesting a
          recalculation.
        </p>
      </>,
      () => {
        handleRecalculation();
      },
    );
  };

  const RequestRecalculationBtn = () => {
    return (
      <Button
        onClick={onConfirmation}
        disabled={!updateAttempted}
        variant="contained"
      >
        Request Recalculation
      </Button>
    );
  };

  const defaultHedgePositionPreset: UltraTablePreset = {
    id: 'default',
    name: 'default',
    columns: [
      ColumnFields.INDEX,

      ...default_columns.map((col) => col.column_key),
    ],
  };

  // This is for always having non filterable columns
  // TODO: a better solution will be setting the ColumnDef to not filterable in tanstack
  const exclusions = [
    'index',
    ...default_columns.map((col) => col?.column_key),
  ];

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      {/* <NavigationGuard
        when={!isDataEqual || updateAttempted}
        message={
          updateAttempted
            ? 'All local changes have been submitted, but the values will only affect calculations in the next run of the fund. If you would like them to be used in the calculation of the currently selected fund, please click the "request calculation" button. Otherwise, you can navigate away.'
            : ''
        }
      /> */}

      <PortalWarning />

      <UltraTable<PortalUpdatePosition>
        title={`Position Update Portal - ${positionDate}`}
        tableData={tableData}
        getRowCanExpand={() => true}
        exclusions={exclusions}
        columnSelector={{ select: true, group: false }} // hacky solution for grouping on manco
        actionBtns={[<RequestRecalculationBtn />]}
        {...(presets &&
          presets.length > 0 && {
            presets: {
              default: presets[0],
              allPresets: presets,
            },
          })}
        isEditable
        onDataComparison={handleDataComparison}
        positionDate={positionDate}
        pdfConfig={{
          pdfIdentifier: `${fundId}_position_portal`,
          pdfTitle: `Portal Data - ${fundId}`,
          pdfExportGroupName: 'portal_page',
          pdfExportGroupOrder: 5,
          pdfFileName: `exposure-${fundId}-${
            positionDate || formatDateForCheckingState(new Date())
          }`,
        }}
      />
    </Grid>
  );
};

const PositionUpdatePortal: React.FC<FundInfoComponentProps> = (props) => {
  return (
    <PortalFetchWrapper
      {...props}
      component={<PositionUpdatePortalComponent {...props} />}
    />
  );
};

export default PositionUpdatePortal;

// Create data for the UltraTable
export function MutateDataToIncludeEditableFields(
  data: any,
  authorised_funds: any,
  transformedAuthKeyNameArr: any,
) {
  // Filter data to only include entries whose keys are in the authorised_funds array
  // This code must be relayed to BE on how to better structure the responses to avoid massive filtering on FE

  // Here we are filtering the data to only include the funds that are authorised
  const authorisedEditFunds = Object.keys(data)
    .filter((key) => {
      return authorised_funds.includes(key);
    })
    .reduce((obj: any, key: any) => {
      obj[key] = data[key];
      return obj;
    }, {});

  // Here we are mapping the authorised funds to include the editable fields by adding it as a property to the positions
  Object.keys(authorisedEditFunds).filter((key) => {
    const fundData = authorisedEditFunds[key];

    const mutatedFundData = fundData.map((position: any) => {
      return {
        ...position,
        editable_fields: transformedAuthKeyNameArr,
      };
    });

    // THIS IS A MUTATION OF THE ORIGINAL DATA -- NOT IDEAL SOLUTION BUT HACK IN FOR NOW
    data[key] = mutatedFundData;
  });

  return data;
}

export function MutateColumnsToIncludeEditableFields(
  columns: any[],
  transformedAuthKeyNameArr: any[],
): ColumnData[] {
  // Create a map of existing columns for quick lookup
  const columnMap = new Map(columns.map((col) => [col.id, col]));

  transformedAuthKeyNameArr.forEach((auth) => {
    if (columnMap.has(auth.key_name)) {
      // Update existing column to include editable auth
      columnMap.set(auth.key_name, {
        ...columnMap.get(auth.key_name),
        editable: auth,
      });
    } else {
      // Create a new column and add it
      columnMap.set(auth.key_name, {
        id: auth.key_name,
        editable: auth,
        filterVariant: 'text',
      });
    }
  });

  // Return updated columns as an array
  return Array.from(columnMap.values());
}

function createHedgeFundPresets(
  data: any[],
  defaultColumns: any[],
): UltraTablePreset[] {
  const map = new Map<string, string[]>();

  data.forEach((item) => {
    if (!map.has(item.raptor_tab_title)) {
      map.set(item.raptor_tab_title, []);
    }
    map.get(item.raptor_tab_title)?.push(item.key_name);
  });

  const presets: UltraTablePreset[] = [];

  map.forEach((columns, title) => {
    const combinedColumns = [
      ColumnFields.INDEX,
      ...defaultColumns.map((col) => col.column_key),
      ...columns,
    ];

    const uniqueColumns = Array.from(new Set(combinedColumns));

    presets.push({
      id: title,
      name: title,
      columns: [
        ...uniqueColumns,
        // 'etf_classification---x3A/8n2/G3c/w5L---investment_type',
      ],
    });
  });

  return presets;
}
